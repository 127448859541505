<template>
  <div class="p-2 bg-white rounded mt-2">
    <div class="flex items-center space-x-2"> 
      <div class="flex flex-auto items-center justify-between">
        <h4 class="font-semibold text-gray-800 text-lg mb-2">{{ supplier.name }}</h4>
        <a href="#" @click.prevent="deleteSupplier(supplier.id)" class="text-red-500 flex space-x-2 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          <span>Delete</span>
        </a>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <old-purchase-order
        v-for="po in supplier.old_purchase_orders"
        :key="po.id"
        :po="po"
        :supplier-index="index"
        :supplier-id="supplier.id"
      >
      </old-purchase-order>
    </div>
    <div class="flex items-start flex-auto space-x-4 mt-4">
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Quotes</h4>
        <div v-for="attachment in quoteAttachments" class="rounded-xl p-1 bg-gray-50 mb-1" :key="attachment.id">
          <a :href="attachment.google_drive_link" target="_blank" class="flex items-center space-x-2">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
            <span class="text-gray-700 text-sm">{{ attachment.name }}</span>
          </a>
        </div>
        <direct-upload upload-url='/rails/active_storage/direct_uploads' :multiple='true' category="quote" label='Drop files into this area to start upload' @upload='uploadCallback'>
        </direct-upload>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Purchase Orders</h4>
        <div v-for="attachment in poAttachments" class="rounded-xl p-1 bg-gray-50 mb-1" :key="attachment.id">
          <a :href="attachment.google_drive_link" target="_blank" class="flex items-center space-x-2">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
            <span class="text-gray-700 text-sm">{{ attachment.name }}</span>
          </a>
        </div>
        <direct-upload upload-url='/rails/active_storage/direct_uploads' :multiple='true' category="old_purchase_order" label='Drop files into this area to start upload' @upload='uploadCallback'>
        </direct-upload>
      </div>
      <div class="flex-1">
        <h4 class="font-semibold text-gray-800">Invoices</h4>
        <div v-for="attachment in invoiceAttachments" class="rounded-xl p-1 bg-gray-50 mb-1" :key="attachment.id">
          <a :href="attachment.google_drive_link" target="_blank" class="flex items-center space-x-2">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
            <span class="text-gray-700 text-sm">{{ attachment.name }}</span>
          </a>
        </div>
        <direct-upload upload-url='/rails/active_storage/direct_uploads' :multiple='true' category="invoice" label='Drop files into this area to start upload' @upload='uploadCallback'>
        </direct-upload>
      </div>
    </div>
    <div class="flex items-center space-x-2 mt-6 mb-4">
      <div class="flex-1">
        <a :href="`/staff/opportunities/${opportunityId}/trades/${supplier.id}/old_purchase_orders`" data-method="POST" class="bg-brand-light px-4 py-2 mr-2 text-brand-dark rounded-lg font-semibold">
          Create Quote Request / Purchase Order
        </a>
        <a :href="`/staff/opportunities/${opportunityId}/trades/${supplier.id}/old_purchase_orders?po_type=all`" data-method="POST" class="bg-brand-light px-4 py-2 text-brand-dark rounded-lg font-semibold">
          Create PO With All {{ tradeName }} Items
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import DirectUpload from './direct_upload.vue';
import { mapActions, mapState } from 'vuex';
import OldPurchaseOrder from './old_purchase_order.vue';

export default {
  props: ['supplier', 'index', 'tradeName'],
  components: { OldPurchaseOrder, DirectUpload },
  data() {
    return {
      doc_type_labels: {
        'quote_request': 'Quote Request',
        'old_purchase_order': 'Purchase Order'
      }
    }
  },
  computed: {
    ...mapState(['opportunityId']),
    quoteAttachments() {
      return this.supplier.trade_attachments.filter(ta => ta.category === 'quote');
    },
    poAttachments() {
      return this.supplier.trade_attachments.filter(ta => ta.category === 'old_purchase_order');
    },
    invoiceAttachments() {
      return this.supplier.trade_attachments.filter(ta => ta.category === 'invoice');
    }
  },
  methods: {
    ...mapActions([
      'saveAttachment',
      'deleteSupplier'
    ]),
    uploadCallback({ file, blob, category }) {
      this.saveAttachment({ projectTradeId: this.supplier.id, signed_id: blob.signed_id, name: blob.filename, category });
    },
  }
}
</script>
