import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "input", "filterable", "link", "parent", "grandParent" ];
    static values = {
        filterParent: { type: Boolean, default: false },
        filterGrandParent: { type: Boolean, default: false }}

    filterBy = "";

    connect() {
        this.filter()
    }

    filter(event) {
        this.filterBy = this.inputTarget.value.toLowerCase();

        this.filterableTargets.forEach((el, i) => {
            let filterableString = el.getAttribute("data-filter-string");

            let hide = filterableString.includes(this.filterBy);
            el.classList.toggle("hidden", !hide);
        })

        //Append filter_value as query param to links
        this.linkTargets.forEach((element) => {
            let url = new URL(element.href);
            let params = new URLSearchParams(url.search);

            params.set("filter_value", filterBy);
            const new_url = new URL(`${url.origin}${url.pathname}?${params}`);
            element.href = new_url.href;
        });

        if (this.filterParentValue) { this.filterParent(); }
    }

    filterParent() {
        this.parentTargets.forEach((parentElement) => {
            if (parentElement.hasAttribute("data-filter-string")) {
                let filterableString = parentElement.getAttribute("data-filter-string");
                if (filterableString.includes(this.filterBy)) {
                    parentElement.classList.toggle("hidden", false);
                    return;
                }
            }

            let childElements = parentElement.querySelectorAll(`:not(.hidden)[data-filter-target*=\"filterable\"]`);

            let hide = childElements.length == 0;
            parentElement.classList.toggle("hidden", hide);
        });

        if (this.filterGrandParentValue) { this.filterGrandParent(); }
    }

    filterGrandParent() {
        this.grandParentTargets.forEach((grandParentElement) => {

            let childElements = grandParentElement.querySelectorAll(`:not(.hidden)[data-filter-target*=\"parent\"]`);

            let hide = childElements.length == 0;

            grandParentElement.classList.toggle("hidden", hide);
        });
    }
}