import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['subTotal']
  connect() {
    console.log("Test Controller Attached!");
  }

  updateTotal(event) {
    let itemId = event.target.dataset.itemId;
    let quantity = parseFloat(document.getElementById(`quantity_${itemId}`).value);
    let price = parseFloat(document.getElementById(`price_${itemId}`).value);
    let subTotal = Number.parseFloat(price * quantity).toFixed(2);

    document.getElementById(`sub_total_${itemId}`).value = subTotal;
    // update po_total
    let po_total = 0.00;
    this.subTotalTargets.forEach(subTotal => {
      po_total += parseFloat(subTotal.value);
    });

    document.getElementById(`po_total`).innerText = po_total;
  }
}

