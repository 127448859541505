import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    opportunityId: null,
    csrfToken: '',
    filter: '',
    trades: [],
    suppliers: []
  },
  getters: {
    exportAvailable(state) {
      return state.suppliers.some(s => s.old_purchase_orders.some(po => po.quote_status === 'approved'));
    },
    tradeSuppliers: (state) => (id) => {
      return state.suppliers.filter(s => s.trade_id === id);
    },
    tradesWithSuppliers(state) {
      if (state.filter) {
        return state.trades.filter(trade => {
          const activeSuppliers = state.suppliers.filter(supplier => supplier.trade_id === trade.id);
          const hasFilteredPos = activeSuppliers.some(as => as.old_purchase_orders.some(po => po.quote_status === state.filter));

          if (activeSuppliers.length > 0 && hasFilteredPos) {
            return true
          };
        }).map(trade => {
          const activeSuppliers = state.suppliers.filter(supplier => {
            return supplier.trade_id === trade.id && supplier.old_purchase_orders.some((po => po.quote_status === state.filter));
          }).map(supplier => {
            return {
              ...supplier,
              old_purchase_orders: supplier.old_purchase_orders.filter(po => {
                return po.quote_request === state.filter;
              })
            }
          });

          return {
            ...trade,
            activeSuppliers
          }
        });
      }

      return state.trades.map(trade => {
        const activeSuppliers = state.suppliers.filter(supplier => supplier.trade_id === trade.id);

        return {
          ...trade,
          activeSuppliers
        };
      });
    }
  },
  mutations: {
    setId(state) {
      state.csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
      state.opportunityId = document.getElementById('opportunity-id').dataset.id;
    },
    updateAttribute(state, { key, value }) {
      state[key] = value;
    },
    updateTradeSupplier(state, { trade, event}) {
      let trades = [...state.trades];
      const tradeIndex = trades.findIndex(t => t.id === trade.id);

      trades[tradeIndex].selectedSupplier = event.target.value;

      state.trades = trades;
    },
    updateTrades(state, trades) {
      state.trades = trades;
    },
    setExistingSuppliers(state, suppliers) {
      state.suppliers =  [...suppliers];
    },
    addNewSupplier(state, supplier) {
      state.suppliers = [...state.suppliers, supplier];
    },
    updateSupplierAttribute(state, { id, attr, value }) {
      let suppliers = [...state.suppliers];
      let supplierIndex = suppliers.findIndex(s => s.id === id);

      suppliers[supplierIndex][attr] = value;

      state.suppliers = suppliers;
    },
    updateOldPurchaseOrderAttribute(state, { supplierId, id, attr, value }) {
      let suppliers = [...state.suppliers];
      const supplierIndex = suppliers.findIndex(s => s.id === supplierId);
      const poIndex = suppliers[supplierIndex].old_purchase_orders.findIndex(po => po.id === id);

      suppliers[supplierIndex].old_purchase_orders[poIndex][attr] = value;

      state.suppliers = suppliers;
    },
    addAttachmentToSupplier(state, { attachment, projectTradeId }) {
      const supplierIndex = state.suppliers.findIndex(s => s.id == projectTradeId);
      let suppliers = [...state.suppliers];
      
      suppliers[supplierIndex].trade_attachments = [
        ...suppliers[supplierIndex].trade_attachments,
        attachment
      ];

      state.suppliers = suppliers;
    },
    toggleTrade(state, id) {
      const tradeIndex = state.trades.findIndex(t => t.id === id);

      Vue.set(state.trades[tradeIndex], 'toggled', !state.trades[tradeIndex].toggled);
    },
    removeSupplier(state, { id, supplier_id }) {
      let suppliers = [...state.suppliers];
      const supplierIndex = suppliers.findIndex(s => s.id === id);
      
      suppliers.splice(supplierIndex, 1);

      state.suppliers = suppliers;
    },
    updateFilter(state, filter) {
      state.filter = filter;
    }
  },
  actions: {
    loadTrades({ commit }) {
      const url = `/staff/trades.json`;

      axios.get(url).then(res => {
        const formattedTrades = res.data.trades.map(t => {
          return {
            ...t,
            toggled: false,
            selectedSupplier: null
          }
        });

        commit('updateAttribute', { key: 'trades', value: formattedTrades });
      });
    },
    loadSuppliers({ state, commit }) {
      const url = `/staff/opportunities/${state.opportunityId}/trades.json`;

      axios.get(url).then(res => {
        commit('setExistingSuppliers', res.data.suppliers);
      })
    },
    updateOldPurchaseOrder({ state, commit }, { supplierId, id, attr, value }) {
      let po_attrs = {
        authenticity_token: state.csrfToken,
        old_purchase_order: {}
      };
      po_attrs.old_purchase_order[attr] = value;
      
      const url = `/staff/old_purchase_orders/${id}.json`;

      axios.patch(url, po_attrs).then(res => {
        commit('updateOldPurchaseOrderAttribute', { supplierId, id, attr, value });
      });
    },
    updateSupplier({ state, commit }) {
      let supplier_attrs = {
        authenticity_token: state.csrfToken,
        project_trade: {}
      };
      supplier_attrs.project_trade[attr] = value;
      
      const url = `/staff/opportunities/${state.opportunityId}/trades/${id}.json`;

      axios.patch(url, supplier_attrs).then(res => {
        commit('updateSupplierAttribute', { id, attr, value });
      });
    },
    addSupplier({ state, commit }, trade) {
      let trades = [...state.trades];
      const tradeIndex = trades.findIndex(t => t.id === trade.id);
      const supplierId = trades[tradeIndex].selectedSupplier;

      const supplier_attrs = {
        authenticity_token: state.csrfToken,
        project_trade: {
          trade_id: trades[tradeIndex].id,
          supplier_id: supplierId,
          quote_attachments: [],
          invoice_attachments: [],
          photos: [],
          opportunity_id: state.opportunityId
        }
      };
      const url = `/staff/opportunities/${state.opportunityId}/trades.json`;

      axios.post(url, supplier_attrs).then(res => {
        trades[tradeIndex].selectedSupplier = null;
        trades[tradeIndex].toggled = true;
    
        commit('updateTrades', trades);
        commit('addNewSupplier', res.data);
      });
    },
    deleteSupplier({ state, commit }, supplier_id) {
      if (confirm("Are you sure you want to delete this supplier and ALL their quote requests and PO's? This cannot be undone.")) {
        const url = `/staff/opportunities/${state.opportunityId}/trades/${supplier_id}`;
        const params = {
          authenticity_token: state.csrfToken
        }

        axios.delete(url, { params }).then(res => {
          commit('removeSupplier', res.data);
        });
      }
    },
    saveAttachment({ state, commit }, { projectTradeId, signed_id, name, category }) {
      const url = `/staff/opportunities/${state.opportunityId}/trades/${projectTradeId}/trade_attachments`;
      const data = {
        authenticity_token: state.csrfToken,
        trade_attachment: {
          name,
          temp_upload: signed_id,
          category,
          project_trade_id: projectTradeId
        }
      };

      axios.post(url, data).then(res => {
        let attachment = data.trade_attachment;
        attachment.id = res.data.id;

        commit('addAttachmentToSupplier', { attachment, projectTradeId });
      });
    }
  },
});

export default store;