import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

const DEFAULT_RESPONSE_KIND = 'turbo_stream';

export default class extends Controller {
  static targets = ['input'];

  connect() {
  }

  send(event) {
    const el = event.currentTarget;
    if (!el.dataset.url) { return; }

    const URL = el.dataset.url;
    const responseKind = el.dataset.responseKind ? el.dataset.responseKind : DEFAULT_RESPONSE_KIND;
    let data = new FormData();

    this.inputTargets.forEach(el => {
      data.append(el.name, el.value);
    });

    post(URL, { body: data, responseKind: responseKind });
  }
}

