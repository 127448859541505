//inherit from Toggle and add open & close icons
import { Toggle } from "tailwindcss-stimulus-components"
import { post } from "@rails/request.js"

export default class ButtonDropdown extends Toggle {
    hasBeenClicked = false;
    static targets = ["open", "closed"];
    static values = {
        externalOpen: { type: Boolean, default: false },
        responseKind: { type: String, default: 'turbo-stream'},
        url: String,
    }

    externalOpenValueChanged(value, previousValue) {
        if (value && !this.openValue) {
            this.toggle();
        }
    }

    toggle(event) {
        if (this.hasUrlValue && !this.hasBeenClicked) {
            this.hasBeenClicked = true;
            post(this.urlValue, { body: '', responseKind: this.responseKindValue });
        }

        super.toggle(event);

        if(this.hasOpenTarget && this.hasClosedTarget) {
            if(this.openValue) {
                this.openTarget.classList.remove('hidden');
                this.closedTarget.classList.add('hidden');
            } else {
                this.openTarget.classList.add('hidden');
                this.closedTarget.classList.remove('hidden');
            }
        }
    }
}